import { defineComponent } from "vue";

import Hero from "@/components/hero/index.vue";
import AnchorLink from "@/components/anchor-link/index.vue";
import KeySector from "@/components/key-sector/index.vue";
import heroBg from "../../assets/images/main-3-bg.png";

export default defineComponent({
  name: "Investment Opportunities",
  components: { Hero, KeySector, AnchorLink },
  setup() {
    const heroSlide = [
      {
        title: "Invest In Nasarawa",
        image: heroBg,
      },
    ];

    return {
      heroSlide,
    };
  },
});
