<template>
  <div id="investment-opportunities">
    <Hero :slideContent="heroSlide" />

    <div class="mt-10 max-w-screen-lg mx-auto pb-20 w-9/10">
      <div class="text-nasida-green">
        <h3 class="text-lg md:text-2xl">Investment Opportunities</h3>
        <h1 class="my-6 text-3xl md:text-4xl">Investing for Nasarawa’s economic growth</h1>
        <p class="text-justify text-nasida-grey">We are continously adopting innovative approaches to collaborate with private partners and development finance institutes to facilitate sectorial development within the state.</p>
      </div>

      <div id="key-sectors" class="my-10 pt-10 space-y-4">
        <div class="text-nasida-green">
          <h1 class="text-4xl md:text-5xl mb-4"><b>Key</b> Sectors</h1>
          <AnchorLink
            text="explore"
            url="sector"
            :showArrow="false"
          />
        </div>
        <div class="grid md:grid-cols-3 gap-5">
          <KeySector
            name="agriculture"
            link="/sector/agriculture"
          />
          <KeySector
            name="mining"
            link="/sector/mining"
          />
          <KeySector
            name="healthcare"
            link="/sector/healthcare"
          />
          <KeySector
            name="transportation"
            link="/sector/transportation"
          />
          <KeySector
            name="energy"
            link="/sector/energy"
          />
          <KeySector
            name="infrastructure"
            link="/sector/infrastructure"
          />
        </div>
      </div>

      <div id="incentives" class="my-10 pt-10">
        <div class="text-nasida-green">
          <h1 class="text-4xl md:text-5xl"><b>Investment</b> Incentives</h1>
        </div>
        <div class="grid md:grid-cols-2 gap-5 mt-10">
          <!-- <div class="card">
            <div class="card-text">
              <h1 class="font-bold text-3xl">Tax Holiday</h1>
              <p>Investors in Nasarawa State are exempted from all Federal, State and Local taxes, rates, custom duties and levies.</p>
            </div>
            <img src="../../assets/images/tax-holiday.png">
          </div>
          <div class="card">
            <div class="card-text">
              <h1 class="font-bold text-3xl">100% repatriation</h1>
              <p>We also offer investors 100% repatriation on capital, profits and dividends.</p>
            </div>
            <img src="../../assets/images/repatriation.png">
          </div> -->
          <div class="card">
            <div class="card-text">
              <h1 class="font-bold text-3xl">One Stop Shop</h1>
              <p>The State has a dedicated agency committed to handholding investors and expediting  adminstrative bottlenecks.</p>
            </div>
            <img src="../../assets/images/one-stop-investment.png">
          </div>
          <div class="card">
            <div class="card-text">
              <h1 class="font-bold text-3xl">Digital Governance</h1>
              <p>Nasarawa State is actively migrating it’s MDAs functions online, to reduce bureaucratic bottle necks.</p>
            </div>
            <img src="../../assets/images/digital-governance.png">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./investment-opportunities.page.js"></script>
<style src="./investment-opportunities.page.css" scoped lang="css"></style>